<template>
	<div>
		<a-modal v-model:visible="visible" width="700px" @cancel="close" @ok="onSubmit">
			<template #title>
				<modalHeader :title="isEdit ? '编辑权限' : '新增权限'"></modalHeader>
			</template>
			
			<div>
				<a-form ref="formRef" :model="modelRef" :label-col="{ span: 4 }" :wrapper-col="{ span: 17 }">
					<a-form-item label="权限类型" name="type" :rules="[{ required: true, message: '必选项不允许为空' }]">
						<a-radio-group v-model:value="modelRef.type">
							<a-radio value="MENU">菜单</a-radio>
							<a-radio value="OPERATION">操作</a-radio>
							<a-radio value="API">接口</a-radio>
							<a-radio value="DATA">数据</a-radio>
						</a-radio-group>
					</a-form-item>
					
					<a-form-item label="权限名称" name="name" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-input v-model:value="modelRef.name" placeholder="请输入权限名称"></a-input>
					</a-form-item>
					
					<a-form-item label="权限码" name="code" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-input v-model:value="modelRef.code" placeholder="请输入权限码"></a-input>
					</a-form-item>
					
					<a-form-item v-if="modelRef.type === 'DATA'" label="数据维度" name="value" :rules="[{ required: true, message: '必选项不允许为空' }]">
						<!-- <a-input v-model:value="modelRef.value" placeholder="请选择数据维度"></a-input> -->
						<a-select v-model:value="modelRef.value" placeholder="请选择数据维度">
							<a-select-option v-for="item in dataList" :key="item.code" :value="item.code">
								{{ item.name }}
							</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item v-else label="权限值" name="value" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-input v-model:value="modelRef.value" placeholder="请输入权限值"></a-input>
					</a-form-item>
					
					<a-form-item label="一级父级" name="groupOneCode">
						<a-select v-model:value="modelRef.groupOneCode" placeholder="请选择一级父级" @change="onGroupOneChange" >
							<a-select-option v-for="item in parentList" :key="item.code" :value="item.code">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="二级父级" name="groupTwoCode">
						<a-select v-model:value="modelRef.groupTwoCode" placeholder="请选择二级父级" @change="onGroupTwoChange" >
							<a-select-option v-for="item in twoList" :key="item.code" :value="item.code">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="三级父级" name="groupThireCode">
						<a-select v-model:value="modelRef.groupThireCode" placeholder="请选择三级父级" @change="onGroupThireChange">
							<a-select-option v-for="item in thireList" :key="item.code" :value="item.code">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="四级父级" name="groupFourCode">
						<a-select v-model:value="modelRef.groupFourCode" placeholder="请选择四级父级" @change="onGroupFourChange" >
							<a-select-option v-for="item in fourList" :key="item.code" :value="item.code">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="五级父级" name="groupFiveCode">
						<a-select v-model:value="modelRef.groupFiveCode" placeholder="请选择五级父级">
							<a-select-option v-for="item in fiveList" :key="item.code" :value="item.code">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="描述说明" name="description">
						<a-input v-model:value="modelRef.description" placeholder="请输入描述说明"></a-input>
					</a-form-item>
					
					<a-form-item label="排序" name="sort" extra="数字越大越优先">
						<a-input-number v-model:value="modelRef.sort" :min="0" :precision="0" placeholder="请输入"></a-input-number>
					</a-form-item>
				</a-form>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import { Icon } from '@/components/icon/icon.js';
	import modalHeader from '@/components/modalHeader/index.vue';
	import { getDataList } from '@/service/modules/system.js';
	import { getParentList, getListByParentId, getMenuDetail, savePower, updatePower } from '@/service/modules/power.js';
	export default {
		components: { Icon, modalHeader },
		data() {
			return {
				id: 0,
				visible: false,
				isEdit: false,
				modelRef: {
					type: 'MENU'
				},
				parentList: [],
				twoList: [],
				thireList: [],
				fourList: [],
				fiveList: [],
				dataList: []
			}
		},
		created() {
			this.getDataList();
		},
		methods: {
			onSubmit() {
				this.$refs.formRef.validate().then(async () => {
					let postData = JSON.parse(JSON.stringify(this.modelRef));
					postData.group = '';
					if (postData.groupOneCode) {
						postData.group = postData.groupOneCode;
					}
					if (postData.groupTwoCode) {
						postData.group = postData.groupTwoCode;
					}
					if (postData.groupThireCode) {
						postData.group = postData.groupThireCode;
					}
					if (postData.groupFourCode) {
						postData.group = postData.groupFourCode;
					}
					if (postData.groupFiveCode) {
						postData.group = postData.groupFiveCode;
					}
					delete postData.groupOneCode;
					delete postData.groupTwoCode;
					delete postData.groupTwoCode;
					delete postData.groupFourCode;
					delete postData.groupFiveCode;
					
					this.loading = true;
					try {
						let ret;
						if (this.isEdit) {
							ret = await updatePower(postData);
						} else {
							ret = await savePower(postData);
						}
						this.loading = false;
						if (ret.code === 200) {
							this.$emit('refresh');
							this.close();
						}
					} catch(err) {
						this.loading = false;
					}
				})
			},
			async getDataList() {
				let ret = await getDataList({});
				if (ret.code === 200) {
					this.dataList = ret.data;
				}
			},
			open(id) {
				this.id = id || 0;
				if (this.id) {
					this.isEdit = true;
					this.visible = true;
					this.getDetail();
					this.getParentList();
				} else {
					this.isEdit = false;
					this.modelRef = {
						type: 'MENU'
					};
					this.parentList = [];
					this.twoList = [];
					this.thireList = [];
					this.fourList = [];
					this.getParentList();
					this.visible = true;
				}
			},
			close() {
				this.$refs.formRef.clearValidate();
				this.visible = false;
			},
			async getDetail() {
				this.loading = true;
				try {
					let ret = await getMenuDetail({
						id: this.id
					})
					this.loading = false;
					if (ret.code === 200) {
						delete ret.data.group;
						let groupList = ret.data.routing?.split(',') || [];
						groupList.forEach((code, index) => {
							if (index === 0) {
								ret.data.groupOneCode = code;
								this.onGroupOneChange(code);
							}
							if (index === 1) {
								ret.data.groupTwoCode = code;
								this.onGroupTwoChange(code);
							}
							if (index === 2) {
								ret.data.groupThireCode = code;
								this.onGroupThireChange(code);
							}
							if (index === 3) {
								ret.data.groupFourCode = code;
								this.onGroupFourChange(code);
							}
							if (index === 4) {
								ret.data.groupFiveCode = code;
							}
						})
						this.modelRef = ret.data;
					}
				} catch(err) {
					this.loading = false;
				}
			},
			onGroupOneChange(code) {
				this.getChildrenList(code, 'twoList')
			},
			onGroupTwoChange(code) {
				this.getChildrenList(code, 'thireList');
			},
			onGroupThireChange(code) {
				this.getChildrenList(code, 'fourList');
			},
			onGroupFourChange(code) {
				this.getChildrenList(code, 'fiveList');
			},
			async getParentList() {
				let ret = await getParentList({});
				if (ret.code === 200) {
					this.parentList = ret.data;
				}
			},
			async getChildrenList(code, key) {
				let ret = await getListByParentId({
					code: code
				});
				if (ret.code === 200) {
					this[key] = ret.data;
				}
			}
		}
	}
</script>

<style>
</style>