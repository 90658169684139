<template>
	<div>
		<a-spin :spinning="loading">
			<a-row>
				<a-col :span="18">
					<a-button @click="onSearchBtn">
						<template #icon><Icon icon="SearchOutlined"/></template>
						查询</a-button>
					<a-button @click="onSearch">						<template #icon><Icon icon="ReloadOutlined"/></template>						刷新</a-button>
				</a-col>
				<a-col :span="6" style="text-align: right;">
					<a-button v-permission="['pc_system_power_add']" type="primary" @click="onAddPower">新增权限</a-button>
				</a-col>
			</a-row>
			
			<div v-show="isSearch">
				<a-form class="label-width ui-form" ref="formRef" :model="formModal" layout="inline">
					<a-form-item label="权限名称" name="name" class="ui-form__item">
						<a-input v-model:value="formModal.name" placeholder="请输入权限名称"></a-input>
					</a-form-item>
					
					<a-form-item label="权限类型" name="type" class="ui-form__item">
						<a-select  allow-clear v-model:value="formModal.type" placeholder="请选择权限类型" style="width: 190px;">
							<a-select-option value="MENU">菜单</a-select-option>
							<a-select-option value="OPERATION">操作项</a-select-option>
							<a-select-option value="API">接口</a-select-option>
							<a-select-option value="DATA">数据</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="权限码" name="code">
						<a-input v-model:value="formModal.code" placeholder="请输入权限码"></a-input>
					</a-form-item>
					
					<a-form-item class="ui-form__item">
						<div style="text-align: center;">
							<a-button @click="onReset">重置</a-button>
							<a-button type="primary" @click="onSearch">搜索</a-button>
						</div>
					</a-form-item>
				</a-form>
			</div>
			
			<div style="margin-top: 20px;">
				<c-Table ref="cTable" :columns="columns" :isRequest="true" :requestFun="getDataFun" :searchData="searchData" rowKey="id" :scroll="{ x: 1500 }" bordered draggable>
						<template #bodyCell="{ column, record, index }">
							<template v-if="column.key === 'type'">
								<span v-if="record.type === 'MENU'">菜单</span>
								<span v-if="record.type === 'OPTION'">操作</span>
								<span v-if="record.type === 'API'">API接口</span>
								<span v-if="record.type === 'DATA'">DATA数据</span>
							</template>
							<template v-if="column.key === 'createTime'">
								{{ transDateTime(record.createTime) }}
							</template>
							<template v-if="column.key === 'action'">
								<a-button v-permission="['pc_system_power_edit']" type="link" size="small" @click="onEdit(record)">编辑</a-button>
								<span></span>
								<a-button v-permission="['pc_system_power_delete']" type="link" size="small" danger @click="onDelete(record)">删除</a-button>
							</template>
						</template>
				</c-Table>
				<!-- <a-table rowKey="id" class="table"
					:columns="columns" 
					:dataSource="list" 
					:pagination="pagination" bordered 
					size="small"
					:scroll="{ x: 1500 }"
				>
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'type'">
							<span v-if="record.type === 'MENU'">菜单</span>
							<span v-if="record.type === 'OPTION'">操作</span>
							<span v-if="record.type === 'API'">API接口</span>
							<span v-if="record.type === 'DATA'">DATA数据</span>
						</template>
						<template v-if="column.key === 'createTime'">
							{{ transDateTime(record.createTime) }}
						</template>
						<template v-if="column.key === 'action'">
							<a-button v-permission="['pc_system_power_edit']" type="link" size="small" @click="onEdit(record)">编辑</a-button>
							<span></span>
							<a-button v-permission="['pc_system_power_delete']" type="link" size="small" danger @click="onDelete(record)">删除</a-button>
						</template>
					</template>
				</a-table> -->
			</div>
		</a-spin>
		<saveModal ref="saveModalRef" @refresh="onSearch"></saveModal>
	</div>
</template>

<script>
	import { Icon } from '@/components/icon/icon.js';
	import { getMenuList, deletePower } from '@/service/modules/power.js';
	import saveModal from './components/saveModal.vue';
	import cTable from '@/components/cTable/index.vue';
	export default {
		components: { Icon, saveModal, cTable },
		data() {
			return {
				loading: false,
				isSearch: false,
				isSort: false,
				isExpand: false,
				searchData: {},
				formModal: {},
				list: [],
				getDataFun: getMenuList,
				columns: [{
					title: '权限码',
					align: 'center',
					dataIndex: 'code'
				}, {
					title: '权限类型',
					align: 'center',
					dataIndex: 'type',
					key: 'type',
					width: 100
				}, {
					title: '权限名称',
					align: 'center',
					dataIndex: 'name'
				}, {
					title: "权限值",
					align: "center",
					dataIndex: "value"
				}, {
					title: '描述说明',
					align: 'center',
					dataIndex: 'description'
				}, {
					title: "排序",
					align: "center",
					dataIndex: "sort",
					width: 80
				}, {
					title: '创建时间',
					align: 'center',
					key: 'createTime',
					width: 160
				}, {
					title: '状态',
					align: 'center',
					key: 'action',
					fixed: 'right',
					width: 110
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						// this.getData();
						this.onSearch()
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						// this.getData();
						this.onSearch()
					}
				}
			}
		},
		created() {
			this.onSearch()
		},
		methods: {
			onReset() {
				this.$refs.formRef.resetFields();
				this.formModal = {}
				this.onSearch();
			},
			onSearch() {
				this.searchData = JSON.parse(JSON.stringify(this.formModal));
				// console.log("this.searchData", this.searchData)
				this.pagination.current = 1;
				// this.getData();
				this.$nextTick(() => {
					this.$refs.cTable.toQuery();
				})
			},
			// async getData() {
			// 	this.loading = true;
			// 	try {
			// 		let res = await getMenuList({
			// 			page: this.pagination.current,
			// 			pageSize: this.pagination.pageSize,
			// 			...this.searchData
			// 		})
			// 		this.loading = false;
			// 		if (res.code === 200) {
			// 			this.list = res.data.list;
			// 			this.pagination.total = res.data.totalCount;
			// 		}
			// 	} catch(e) {
			// 		this.loading = false;
			// 	}
			// },
			onSearchBtn() {
				this.isSearch = !this.isSearch;
			},
			onSort() {
				this.isSort = !this.isSort;
			},
			onAddPower() {
				this.$refs.saveModalRef.open();
			},
			onEdit(item) {
				this.$refs.saveModalRef.open(item.id);
			},
			onDelete(item) {
				this.$confirm({
					title: '提示',
					content: "确定删除吗？",
					onOk: async ()=> {
						try {
							this.loading = true;
							let ret = await deletePower({
								id: item.id
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('删除成功!');
								// this.getData();
								this.onSearch()
							}
						} catch(err) {
							this.loading = false;
						}
					}
				})
			}
		}
	}
</script>

<style scoped>
	.ui-form {
		margin-top: 20px;
		padding: 20px 20px 0;
		background: #F5F7FA;
	}
	.ui-form__middle {
		padding-top: 4px;
		text-align: center;
	}
</style>