import { send } from "../index";

export function getMenuList(data, opts = {}) {
  return send({
    url: "/admin/adminPower/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getMenuDetail(data, opts = {}) {
  return send({
    url: "/admin/adminPower/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function savePower(data, opts = {}) {
	return send({
		url: "/admin/adminPower/add.do",
		method: "POST",
		data,
		...opts
	})
}

export function updatePower(data, opts = {}) {
	return send({
		url: "/admin/adminPower/update.do",
		method: "POST",
		data,
		...opts
	})
}

export function deletePower(data, opts = {}) {
	return send({
		url: "/admin/adminPower/delete.do",
		method: "POST",
		data,
		...opts
	})
}

export function getParentList(data, opts = {}) {
	return send({
		url: "/admin/adminPower/listParent.do",
		method: "POST",
		data,
		...opts
	})
}

export function getListByParentId(data, opts = {}) {
	return send({
		url: "/admin/adminPower/listByParentId.do",
		method: "POST",
		data,
		...opts
	})
}
